import { Injectable } from '@angular/core';
import { ENV, environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UtilsService } from './utils.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable({
  providedIn: 'root'
})
export class AgendaAmbulatoriaService {

  public servRoute: string = ENV.servRoute;
  public profRoute: string = ENV.profRoute;
  public baseApi: string;
  public httpOptions: any;
  public deviceInfo;

  constructor(
    public http: HttpClient, 
    private deviceService: DeviceDetectorService,
    private recaptchaV3Service: ReCaptchaV3Service) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.baseApi = ENV.baseApi + this.servRoute;
    this.httpOptions = {
      headers: new HttpHeaders({
        'X-AppTimezone': "-180",
        'Content-Type': "application/json;",
      })
    };
  }

  getAreas() {
    return this.http.get(this.baseApi + '/Areas?codCanal=PatientPortal');
  }

  getEspecialidadesByGeneric(idArea: any, filtro: any = null, idServicio: string = null) {
    let keyf = (filtro) ? '&filtro=' + filtro : '';
    let ids = (idServicio) ? '&idServicio=' + idServicio : '';

    return this.http.get(this.baseApi + '/Especialidades?codCanal=PatientPortal&idArea=' + idArea + keyf + ids);
  }

  getEspecialidadesByProfesional(idProfesional: string, idArea: string, filtro: any = null) {
    let keyf = (filtro) ? '&filtro=' + filtro : '';
    const area = idArea ? '&idArea=' + idArea : '';
    return this.http.get(this.baseApi + '/Servicios/Rel/Especialidades?codCanal=PatientPortal&idProfesional=' + idProfesional + area + keyf);
  }

  getProfesionales(idArea: string, filtro: any = null, idProfesional: string = null) {
    let keyf = (filtro) ? '&patronNombre=' + filtro : '';
    let idp = (idProfesional) ? '&idProfesional=' + idProfesional : '';

    return this.http.get(this.baseApi + '/Profesionales?codCanal=PatientPortal&idArea=' + idArea + keyf + idp);
  }

  getServiciosByEspecialidad(idEspecialidad, idArea) {
    const area = idArea ? '&idArea=' + idArea : '';
    return this.http.get(this.baseApi + '/Servicios?codCanal=PatientPortal' + area + '&idEspecialidad=' + idEspecialidad).toPromise();

  }

  getProfesionalesByQuery(query: string) {
    return this.http.get(this.baseApi + '/Profesionales?codCanal=PatientPortal&' + query);
  }

  getCentrosByEspecialidad(idServicio: string, idArea: string, idProfesional = null, fromSel = false) {
    let queryProfesional = (idProfesional) ? '&idProfesional=' + idProfesional : '';
    queryProfesional += fromSel ? '&fromSelCentros=true' : '';
    return this.http.get(this.baseApi + '/Centros?codCanal=PatientPortal&idServicio=' + idServicio + '&idArea=' + idArea + queryProfesional);
  }

  getRecursos(queryData: any) {

    let queryProfesional = (queryData.idProfesional) ? '&profesional=' + queryData.idProfesional : '';
    let queryCentro = (queryData.todosCentro) ? '&idRegion=' + queryData.idCentro : '&idCentro=' + queryData.idCentro;
    let endpoint = (queryData.idProfesional) ? 'CuposProfFechas' : 'CuposEspecCentro';
    let fromProfRel = (queryData.fromProfRel) ? '&fromProfRel=true' : '';
    let idArea = (queryData.idArea === 'RIS_IMAGENES') ? '&idArea=RIS_IMAGENES' : '';
    let codCanal = '&codCanal=PatientPortal';
    let idPaciente = (queryData.idPaciente) ? '&idPaciente=' + queryData.idPaciente : '';

    return this.http.get(this.baseApi +
      '/Agenda/' + endpoint + '?tipoResponse=2&fechaInicio=' + queryData.fechaInicio + '&fechaTermino=' + queryData.fechaTermino +
      '&idServicio=' + queryData.idServicio + '&idPlanSalud=' + queryData.idPlanSalud + queryProfesional + queryCentro + fromProfRel + idArea + codCanal + idPaciente
    );
  }

  getPaciente(idn: string, tipoIdPaciente: string, idArea) {
    const area = idArea === 'RIS_IMAGENES' ? '&contextoCod=RIS' : ''
    return this.http.get(this.baseApi + '/Pacientes?codCanal=PatientPortal&idPaciente=' + idn + '&tipoIdPaciente=' + tipoIdPaciente + '&paisIdentificador=CL');
  }

  postPaciente(data: any) {
    return this.http.post(this.baseApi + '/Pacientes', data, this.httpOptions);
  }

  putPaciente(data: any) {
    return this.http.put(this.baseApi + '/Pacientes', data, this.httpOptions);
  }

  postCita(data: any) {
    return this.http.post(this.baseApi + '/Citas', data, this.httpOptions);
  }

  getPlanesSalud(idPaciente, data = null, sendFilter = true) {
    let queryString = '';
    if (sendFilter) {
      queryString = (idPaciente) ? 'idPaciente=' + idPaciente : 'tipoIdPaciente=' + data['tipoDocumento'] + '&idPaciente=' + data['documento'] + '&paisIdentificador=CL'
    }

    return this.http.get(this.baseApi + '/PlanesDeSalud?codCanal=PatientPortal&' + queryString);
  }

  getDatosProfesional(idProfesional: any, rut: string = null) {
    const qstr = (rut) ? `rutProfesional=${rut}` : `codCanal=PatientPortal&idProfesional=${idProfesional}&tipoIdProfesional=PRM`;
    return this.http.get(ENV.baseApi + this.profRoute + '/Perfil?' + qstr);
  }

  geReglasValidacion(query: any) {
    const idArea = query.idArea === 'RIS_IMAGENES' ? '&idArea=RIS_IMAGENES' : '';
    return this.http.get(this.baseApi + '/Agenda/CuposDisponibilidad?codCanal=PatientPortal&idCentro=' + query.idCentro + '&fechaInicio=' + query.fechaInicio + '&fechaTermino=' + query.fechaTermino + '&idServicio=' + query.idServicio + '&idPlanSalud=' + query.idPlanSalud + '&idPaciente=' + query.idPaciente + '&idDisponibilidad=' + query.idDisponibilidad + '&idProfesional=' + query.idProfesional + '&codCanal=PatientPortal' + idArea)
  }

  getMensajes(data, idArea, type = null) {

    let qrProf = "";
    if (data.ResourceId && data.ResourceId != "") {
      qrProf += '&idProfesional=' + data.ResourceId;
    }

    qrProf += '&idArea=' + data.idArea

    return this.http.get(this.baseApi + '/Mensajes?idCentro=' + data.CenterId + '&codCanal=PatientPortal&idServicio=' + data.ServiceId + qrProf);
  }

  buscarCita(data) {
    return this.http.get(this.baseApi + '/Citas/Lista?codCanal=PatientPortal&idPaciente=' + data.idPaciente + '&tipoIdPaciente=' + data.tipoIdPaciente + '&paisIdentificador=CL&fechaCita=' + data.fechaCita);
  }

  cambiarEstadoCita(data) {
    return this.http.put(this.baseApi + '/Citas', data, this.httpOptions);
  }

  postListaDeEspera(data) {
    return this.http.post(this.baseApi + '/ListaDeEspera/Pacientes', data).toPromise();
  }

  getCuposInmediatos() {
    return this.http.get(this.baseApi + '/Agenda/CuposInmediatos?codCanal=PatientPortal&idArea=' + ENV.areaConsultaMedica.id).toPromise();
  }

  getPaises() {
    return this.http.get(this.baseApi + '/Paises?codCanal=PatientPortal').toPromise();
  }

  getRegiones(codPais) {
    return this.http.get(this.baseApi + '/Regiones/Pais?codCanal=PatientPortal&codPais=' + codPais).toPromise();
  }

  getComunas(idRegion) {
    return this.http.get(this.baseApi + '/Comunas/Region?codCanal=PatientPortal&idRegion=' + idRegion).toPromise();
  }

  postProcedimiento(data) {
    return this.http.post(this.baseApi + '/SolicitudProcedimiento', data).toPromise();
  }

  getReglasExclusion(codContexto, data) {

    const idServicio = '&idServicio=' + data.idServicio;
    const idCentro = data.idCentro ? '&idCentro=' + data.idCentro : '';
    const idProfesional = data.idProfesional ? '&idProfesional=' + data.idProfesional : ''

    return this.http.get(this.baseApi + '/ReglasExclusion/Validar?codCanal=PatientPortal&codContexto=' + codContexto + idCentro + idServicio + idProfesional).toPromise();
  }

  getEncuesta(idServicio, idCentro, medioContraste) {
    return this.http.get(this.baseApi + `/Encuesta/Obtener?codCanal=PatientPortal&codExamen=${idServicio}&codCentro=${idCentro}&medioContraste=${medioContraste}`).toPromise();
  }

  getEncuestaGenerica(idArea: string, idEspecialidad: string, idServicio: string) {
    return this.http.get(this.baseApi + `/Encuesta/Obtener?idArea=${idArea}&idEspecialidad=${idEspecialidad}&idServicio=${idServicio}`).toPromise();
  }

  postEncuesta(data) {
    return this.http.post(this.baseApi + `/Encuesta/Registrar`, data).toPromise();
  }

  getAutorizaPago(idcentro, idservicio, idplanesdesalud) {
    return this.http.get(this.baseApi + `/AutorizaPagoCita?idCentro=${idcentro}&idServicio=${idservicio}&idPlanSalud=${idplanesdesalud}`).toPromise();
  }


  validarEnrolamiento(rut) {
    return this.http.get(`${ENV.validarEnrolamiento}?rut=${rut}&f=1`).toPromise();
  }

  autenticar(username, password) {
    const httpHd = {
      headers: new HttpHeaders({
        'Content-Type': "application/json;"
      })
    };
    return this.http.post(ENV.baseApi + ENV.pathAutenticar, { username, password }, httpHd).toPromise();
  }

  listarCitas(query, token) {
    const httpHd = {
      headers: new HttpHeaders({
        'Content-Type': "application/json;",
        'Authorization' : 'Bearer ' +   token
      })
    };
    const queryc = '/Citas/Priv/Lista?'+ query;
    return this.http.get(this.baseApi + queryc ,httpHd).toPromise();
  }


  guardarUsuario(data) {
    return this.http.post(ENV.baseApi + ENV.pathUsuarios + '/Usuarios', data).toPromise()
  }

  validarUsuario(data) {
    return this.http.post(ENV.baseApi + ENV.pathUsuarios + '/Confirmar', data).toPromise()
  }

  recuperarClave(rut) {
    return this.http.get(ENV.baseApi + ENV.pathUsuarios + '/Usuarios/ResetearPass?username=' + rut).toPromise()
  }

  cambiarClave(data) {
    return this.http.post(ENV.baseApi + ENV.pathUsuarios + '/Usuarios/ResetearPass', data).toPromise()
  }

  consultarUsuario(rut) {
    return this.http.get(ENV.baseApi + ENV.pathUsuarios + '/Usuarios/Consultar?username=' + rut).toPromise()
  }

  adjuntarExamenes(data) {
    const url = ENV.rutaSubirDocumentoPaciente
    return this.http.post(url, data).toPromise()
  }

  async crearMarca(marca, clearSession = false) {

    if(!ENV.habilitarMarcasWeb) return;

    if (clearSession) {
      sessionStorage.removeItem('idmarca');
    }

    const browser = this.deviceService.browser.toUpperCase();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    let device = '';

    if (isMobile) {
      device = 'MOBILE';
    } else if (isTablet) {
      device = 'TABLET';
    } else {
      device = 'DESKTOP';
    }

    const sessionData = {
      source: 'LINK DIRECTO',
      device,
      browser
    }

    if (!this.getIdSessionMarca) {
      await this.createIdSession(sessionData);
    }
    if (marca) {
      this.createTag(marca);
    }

  }

  get getIdSessionMarca() {
    return sessionStorage.getItem('idmarca')
  }

  async createIdSession(sessionData) {
    try {
      const res: any = await this.http.post(ENV.urlMarcaWeb + '/Create', sessionData).toPromise();
      sessionStorage.setItem('idmarca', res.id);
    } catch (err) {
      console.log(err)
      sessionStorage.setItem('idmarca', '');
    }
  }

  async createTag(data) {
    data.dni = !data.dni ? '-' : data.dni;
    data.dni_type = !data.dni_type ? '-' : data.dni_type;
    data.into = data.into ? data.into : 'tags';
    return await this.http.post(ENV.urlMarcaWeb + '/Update?id=' + this.getIdSessionMarca, data).toPromise();
  }

  async loginUsuario() {
    const data = {
      username: ENV.apiDerivacionDetalle.username,
      password: ENV.apiDerivacionDetalle.password
    }
    return await this.http.post(`${ENV.apiDerivacionDetalle.api}/usuarios/v1/sesiones/iniciar-sesion`, data).toPromise();
  }

  async obtenerObjetoBase64(documento: string, token: string) {

    this.httpOptions = {
      headers: new HttpHeaders({
       // 'X-AppTimezone': "-180",
        'Content-Type': "application/json;",
        'Authorization': "Bearer " + token
      })
    }

    const env = ENV.apiDerivacionDetalle.ambiente;
    const ruta = ENV.apiDerivacionDetalle.ruta;
    return await this.http.get(`${ENV.apiDerivacionDetalle.api}/almacenamiento/v1/archivosbase/${env}/${ruta}/${documento}`, this.httpOptions).toPromise();

  }

  validarRequiereOrden(data) {
    const queryString = `?idArea=${data.idArea}&idServicio=${data.idServicio}&idCentro=${data.idCentro}`;
    return this.http.get(this.baseApi + '/ValidarRequiereOrden' + queryString).toPromise();
  }

  async cargarOrdenMedica(token, file: any) {
    return new Promise( async resolve => {
      try {

        const data = {
          clienteCod: "agendaWeb",
          apiToken: token,
          contexto: "ordenesMedicas",
          fileName: file.name
        };
  
        const responseFile: any = await this.http.post(ENV.baseApi + ENV.fileRoute + '/upload/Url', data).toPromise();

        if (responseFile.statusCod === "OK") {
          const contentType = file.mimetype.includes("pdf") ? "application/pdf" : "application/octet-stream";
          const httpHeaders = {
            headers: new HttpHeaders({
              'Content-Type': "application/json"
            })
          }

          const data = {
            fileBase64: file.file64,
            mimetypes: contentType,
            url_dinamica: responseFile.signedUrl
          }
          
          this.http.post(ENV.apiCargaOrdenes, data, httpHeaders).toPromise().then( res => {
            resolve({ urlOrden : responseFile.fileUrl });
          }).catch( err => {
            resolve({ urlOrden : null })
          });
        } else {
          resolve({ urlOrden : null })
        }
      } catch (err) {
        console.log(err)
        resolve({ urlOrden : null })  
      }
    });

  }


  toByte(base64) {
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));
    for(let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return Object.keys(array).map( item => array[item]);
  }

  arrayBufferToBase64(buffer: Uint8Array): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  getFileFromSF(filename: string){
    return new Promise( resolve => {
      const host = ENV.archivosSiteFinity;
      this.http.get(`${host}/${filename}`, { responseType: 'arraybuffer' }).subscribe( res => {
        const byteArray = new Uint8Array(res);
          resolve(this.arrayBufferToBase64(byteArray));
      });
    });
  }

  registrarTyC(data) {
    return this.http.post(ENV.apiTyC, data).toPromise();
  }


  getTokenRecaptcha() {
    return new Promise(async (resolve, reject) => {
        this.recaptchaV3Service.execute('WEB').subscribe((token) => { resolve(token) }, err => { reject(null) });
    })
};
}
